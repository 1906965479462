import StatusType from "./enums/statusType";

const DRAFT = StatusType.DRAFT;
const READY = StatusType.READY
const PROCESSING = StatusType.PROCESSING;
const IN_REVIEW = StatusType.IN_REVIEW
const PUBLISHED = StatusType.PUBLISHED;
const FAILED = StatusType.FAILED
const DELETED = StatusType.DELETED;
const ERROR = StatusType.ERROR
const SCHEDULED = StatusType.SCHEDULED
const ACTIVE = StatusType.ACTIVE

export const stateMap = {
    DRAFT: {
      'title': StatusType.toReadable(DRAFT),
      'classNameOuter': 'bg-yellow-100',
      'classNameInner': 'text-yellow-500',
    },
    READY: {
      'title': StatusType.toReadable(READY),
      'classNameOuter': 'bg-yellow-100',
      'classNameInner': 'text-yellow-500',
    },
    PROCESSING: {
      'title': StatusType.toReadable(PROCESSING),
      'classNameOuter': 'bg-blue-400',
      'classNameInner': 'text-white',
    },
    IN_REVIEW: {
      'title': StatusType.toReadable(IN_REVIEW),
      'classNameOuter': 'bg-orange-400',
      'classNameInner': 'text-white',
    },
    PUBLISHED: {
      'title':  StatusType.toReadable(PUBLISHED),
      'classNameOuter': 'bg-green-100',
      'classNameInner': 'text-green-500',
    },
    FAILED: {
      'title': StatusType.toReadable(FAILED),
      'classNameOuter': 'bg-red-100',
      'classNameInner': 'text-red-500',
    },
    DELETED: {
      'title': StatusType.toReadable(DELETED),
      'classNameOuter': 'bg-red-100',
      'classNameInner': 'text-red-500',
    },
    ERROR: {
      'title': StatusType.toReadable(ERROR),
      'classNameOuter': 'bg-red-100',
      'classNameInner': 'text-red-500',
    },
    SCHEDULED: {
      'title': StatusType.toReadable(SCHEDULED),
      'classNameOuter': 'bg-[#b8bbc1]',
      'classNameInner': 'text-[#000000]',
    },
    ACTIVE: {
      'title': StatusType.toReadable(ACTIVE),
      'classNameOuter': 'bg-green-100',
      'classNameInner': 'text-green-500',
    },
};
