class StatusType {
  static DRAFT = "DRAFT"
  static PROCESSING = "PROCESSING"
  static IN_REVIEW = "IN_REVIEW"
  static PUBLISHED = "PUBLISHED"
  static FAILED = "FAILED"
  static DELETED = "DELETED"
  static ERROR = "ERROR"
  static SCHEDULED = "SCHEDULED"
  static ACTIVE = "ACTIVE"
  static READY = "READY"

  static toReadable(type){
    switch(type){
      case this.DRAFT:
      case this.READY:
        return "Ready";
      case this.PROCESSING:
        return "Processing";
      case this.IN_REVIEW:
        return "In Review";
      case this.PUBLISHED:
        return "Published";
      case this.FAILED:
        return "Failed";
      case this.DELETED:
        return "Deleted";
      case this.ERROR:
        return "Error";
      case this.SCHEDULED:
        return "Scheduled";
      case this.ACTIVE:
        return "Active";
      default:
        return null;
    }
  }
}

export default StatusType;
