class CampaignType {
  static MANUAL = "MANUAL"
  static AUTOMATIC = "AUTOMATIC"

  static toReadable(type){
    switch(type){
      case this.MANUAL:
        return "Manual"
      case this.AUTOMATIC:
        return "Multiple Post with CSV file"
      default:
        return null;
    }
  }

  static getTypeFromString(typeString) {
    switch (typeString) {
      case "MANUAL":
        return this.MANUAL;
      case "AUTOMATIC":
        return this.AUTOMATIC;
      default:
        return null;
    }
  }

  static getReadableFromString(typeString) {
    return CampaignType.toReadable(CampaignType.getTypeFromString(typeString));
  }


}

export default CampaignType;
