import StatusType from "../data/enums/statusType";

// Post Status
export const postStatusStyles = {
    selected: {
      [StatusType.PROCESSING]: "bg-main-blue text-white",
      [StatusType.FAILED]: "bg-[#EF4444] text-white",
      [StatusType.DRAFT]: "bg-main-blue text-white",
      [StatusType.READY]: "bg-main-blue text-white",
    },
    container: {
      [StatusType.PROCESSING]: "bg-none text-[#12121280]",
      [StatusType.FAILED]: "bg-[#FEF0F0] text-[#EF4444]",
      [StatusType.DRAFT]: "bg-light-blue text-[#121212]",
      [StatusType.READY]: "bg-light-blue text-[#121212]",
    },
    textContainer: {
      [StatusType.PROCESSING]: "bg-main-blue text-white",
      [StatusType.FAILED]: "bg-[#EF4444] text-white",
      [StatusType.DRAFT]: "bg-main-blue",
      [StatusType.READY]: "bg-main-blue",
    },
  };
