import React from "react";
import { Modal } from "flowbite-react";

import ActionButtonsGroup from "../buttons/ActionButtonsGrup";

const DeleteConfirmationModal = ({
    entityName = "Entity",
    subEntityName,
    showModal,
    setOpenModal,
    confirmDelete,
}) => {
    const isSocialAccountEntity = entityName === 'Social Account';
    const headerMessage = isSocialAccountEntity ? `Disconnect ${entityName}` : `Delete ${entityName}`;

    let message = isSocialAccountEntity ? 'Are you sure you want to disconnect this social account?' : `Are you sure you want to delete this ${entityName.toLowerCase()}?`;
    message += subEntityName ? ` This is a permanent action and will also delete the ${subEntityName} related to it.`: isSocialAccountEntity ? '' : '.';
    return (
        <Modal
            show={showModal}
            size="lg"
            onClose={() => setOpenModal(false)}
        >
            <Modal.Header className="text-center">
                {headerMessage}
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-col justify-center items-center space-y-4">
                    <div className="text-center">
                        {message}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="w-full flex-row justify-end space-x-3">
                    <ActionButtonsGroup
                        saveLabel="Confirm"
                        onSave={confirmDelete}
                        onCancel={() => setOpenModal(false)}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;
