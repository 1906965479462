import { fetchAuthSession } from "aws-amplify/auth";
import { callbackURL } from "./common";
import {
  APPS_LINKEDIN_OAUTH_URL,
  APPS_LINKEDIN_CLIENT_ID,
  APPS_X_OAUTH_URL,
  APPS_X_CLIENT_ID,
  API_SERVER,
} from "../config/constants";
import { ReactComponent as LinkedInIcon } from "../assets/linkedin_icon.svg";
import { ReactComponent as XIcon } from "../assets/x.svg";

import * as NotificationService from "./notificationService";
import SocialNetworkType from "../data/enums/socialNetworkType";


export const socialPlatformIconsMap = {
  [SocialNetworkType.LINKEDIN]: LinkedInIcon,
  [SocialNetworkType.X]: XIcon,
};

export const getSocialPlatformName = (name) => {
  let appName = name;
  if (name?.includes("#")) appName = name.split("#")[0];
  return appName;
};

export const getSocialPlatformIcon = (name) => {
  const Icon = socialPlatformIconsMap[getSocialPlatformName(name)];
  return <Icon className="mr-3" />;
};

export const socialPlatformConnectCallbacksMap = {
  LinkedIn: (accountName) => {
    const metadata = JSON.stringify({account_name: accountName });
    const encodedMetadata = btoa(metadata);
    const clientId = APPS_LINKEDIN_CLIENT_ID;
    const callbackUrl = callbackURL("LinkedIn");
    const scopes = "w_member_social%20openid%20profile%20r_basicprofile";

    window.location = `${APPS_LINKEDIN_OAUTH_URL}
?client_id=${clientId}
&redirect_uri=${callbackUrl}
&response_type=code
&state=${encodedMetadata}
&scope=${scopes}`;
  },
  X: (accountName) => {
    const metadata = JSON.stringify({account_name: accountName });
    const encodedMetadata = btoa(metadata);
    const clientId = APPS_X_CLIENT_ID;
    const callbackUrl = callbackURL("X");
    const scopes = "tweet.read%20tweet.write%20users.read%20offline.access";

    window.location = `${APPS_X_OAUTH_URL}
?client_id=${clientId}
&redirect_uri=${callbackUrl}
&response_type=code
&scope=${scopes}
&state=${encodedMetadata}
&code_challenge=challenge
&code_challenge_method=plain`;
  },
};

export const socialPlatformDisconnectCallbacksMap = {
  LinkedIn: (appId, setIsLoading, fetchApps) => {
    fetchAuthSession().then((session) => {
      setIsLoading(true);
      let subAppId = appId;
      if (appId.includes('#') && appId.split('#').length >= 2){
        subAppId = appId.split('#')[1];
      }
      fetch(API_SERVER + `/apps/linkedin/disconnect/${subAppId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.message || "Network response was not ok");
          }
          NotificationService.notifySuccess(data.status || "Social network disconnected successfully");
          fetchApps();
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(`Failed to disconnect: ${errorMessage}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  },
  X: (appId, setIsLoading, fetchApps) => {
    fetchAuthSession().then((session) => {
      setIsLoading(true);
      let subAppId = appId;
      if (appId.includes('#') && appId.split('#').length >= 2){
        subAppId = appId.split('#')[1];
      }
      fetch(API_SERVER + `/apps/x/disconnect/${subAppId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.message || "Network response was not ok");
          }
          NotificationService.notifySuccess(data.status || "Social network disconnected successfully");
          fetchApps();
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(`Failed to disconnect: ${errorMessage}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  },
};