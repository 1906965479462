import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchApps } from "../services";
import Loading from "./common/Loading";
import SocialAccountsTable from "./settings/SocialAccountsTable";

function Settings() {
  const [isDeletingApp, setIsDeletingApp] = useState(false);
  const appsQuery = useQuery({
    queryKey: ["user-social-accounts"],
    queryFn: fetchApps,
    staleTime: 10 * 1000,
  });

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Settings</h1>
      <div className="text-sm font-semibold text-center text-gray-500 border-b border-gray-200">
        <ul className="flex flex-wrap -mb-px">
          <li className="me-2">
            <a
              href="#"
              className="inline-block py-3 text-main-blue border-b-[3px] border-main-blue rounded-t-lg active"
              aria-current="page"
            >
              Target Platform
            </a>
          </li>
        </ul>
      </div>
      <hr />
      <br />
      {appsQuery.isLoading || isDeletingApp ? (
        <Loading />
      ) : (
        <div className="w-full">
          <SocialAccountsTable
            apps={appsQuery.data}
            isLoading={appsQuery.isLoading}
            setIsLoading={setIsDeletingApp}
            fetchApps={appsQuery.refetch}
          />
        </div>
      )}
    </div>
  );
}

export default Settings;