import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaChevronDown,
  FaChevronUp,
  FaTrash,
} from "react-icons/fa";

import { formatDateString, extractPkSk } from "../../utils/common";
import { stateMap } from "../../data/CampaignStates";
import { isEmpty } from "../../utils/validations";

const CampaignRow = ({
  currentCampaign,
  expandedRow,
  toggleExpandRow,
  deleteCampaignHandler,
}) => {
  const navigate = useNavigate();
  const posts = currentCampaign.posts;

  const currentViews = (!isEmpty(currentCampaign.metrics?.metrics)) ? currentCampaign.metrics?.metrics?.impression_count : '-';
  const currentReactions = (!isEmpty(currentCampaign.metrics?.metrics)) ? currentCampaign.metrics?.metrics?.like_count : '-';
  const currentComments = (!isEmpty(currentCampaign.metrics?.metrics)) ? currentCampaign.metrics?.metrics?.reply_count : '-';

  const [currentMetrics, setCurrentMetrics] = useState({
    views: currentViews,
    reactions: currentReactions,
    comments: currentComments,
  });

  const [initialMetrics, setInitialMetrics] = useState({
    views: currentViews,
    reactions: currentReactions,
    comments: currentComments,
  });

  const { pkuuid: projectSkuuid, skuuid: campaignSkuuid } = extractPkSk(currentCampaign.id);
  const moveToCurrentCampaign = () => navigate(`/mkt/projects/${projectSkuuid}/campaigns/${campaignSkuuid}/assistant`);

  useEffect(() => {
    setInitialMetrics({
      views: currentViews,
      reactions: currentReactions,
      comments: currentComments,
    });
    setCurrentMetrics({
      views: currentViews,
      reactions: currentReactions,
      comments: currentComments,
    });
  }, [currentViews, currentReactions, currentComments]);


  return (
    <React.Fragment>
      <tr
        className={`hover:bg-light-blue hover:cursor-pointer border-b`}
      >
        <td
          className={`py-3 px-4 w-60`}
          onClick={moveToCurrentCampaign}
        >
          <div className="h-20 flex-col justify-center items-start gap-1 inline-flex">
            <div className="self-stretch text-black text-lg font-semibold leading-normal tracking-tight">
              {currentCampaign.title}
            </div>
            <div className="self-stretch text-light-gray text-base font-normal leading-snug tracking-tight">
              {formatDateString(currentCampaign.created_at)}
            </div>
          </div>
        </td>
        <td className="py-3 px-4 w-32" onClick={moveToCurrentCampaign}>
          <div
            className="h-6 px-3 rounded justify-start items-center gap-1.5 inline-flex "
          >
            <div
              className="text-center text-sm font-normal leading-tight "
            >
              -
            </div>
          </div>
        </td>
        <td className="py-3 px-4 w-10" onClick={moveToCurrentCampaign}>
          <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
            {currentMetrics.views}
          </span>
        </td>
        <td className="py-3 px-4 w-10" onClick={moveToCurrentCampaign}>
          <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
            {currentMetrics.reactions}
          </span>
        </td>
        <td className="py-3 px-4 w-10" onClick={moveToCurrentCampaign}>
          <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
            {currentMetrics.comments}
          </span>
        </td>
        <td className="py-8 px-5 w-20">
          {posts &&
            <div className="flex justify-start items-center space-x-7">
              <div
                onClick={() => {
                  if (posts.length > 0) {
                    toggleExpandRow(currentCampaign.sk);
                  }
                }}
                className={`cursor-pointer ${posts.length === 0 ? 'cursor-not-allowed opacity-50' : ''}`}
              >
                {expandedRow === currentCampaign.sk ? (
                  <FaChevronUp size={20} />
                ) : (
                  <FaChevronDown size={20} />
                )}
              </div>
              <div
                onClick={() => deleteCampaignHandler(`${currentCampaign.pk}&${currentCampaign.sk}`)}
                className="cursor-pointer"
              >
                <FaTrash
                  size={20}
                  className="text-gray-600 hover:text-red-600"
                />
              </div>
            </div>
          }
        </td>
      </tr>
    </React.Fragment>
  );
};

export default CampaignRow;
