import React from 'react';
import csvTemplate from '../../assets/files/campaign_csv_template.csv';

export default function DownloadCsvTemplate() {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = csvTemplate;
        link.download = 'campaign_csv_template.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex justify-center items-center">
            <h1
                onClick={handleDownload}
                className="text-main-blue underline cursor-pointer hover:text-blue-700"
            >
                Download csv template
            </h1>
        </div>
    );
}