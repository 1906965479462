import React, { useEffect, useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";

import { formatDateString } from "../../utils/common";
import { stateMap } from "../../data/CampaignStates";
import { isEmpty } from "../../utils/validations";

const PostRow = ({
  currentPost,
  index,
  expandedRow,
  toggleExpandRow
}) => {
  const targets = currentPost.target_platforms;

  const currentViews = (!isEmpty(currentPost.metrics?.metrics)) ? currentPost.metrics?.metrics?.impression_count : '-';
  const currentReactions = (!isEmpty(currentPost.metrics?.metrics)) ? currentPost.metrics?.metrics?.like_count : '-';
  const currentComments = (!isEmpty(currentPost.metrics?.metrics)) ? currentPost.metrics?.metrics?.reply_count : '-';

  const [currentMetrics, setCurrentMetrics] = useState({
    views: currentViews,
    reactions: currentReactions,
    comments: currentComments,
  });

  const [initialMetrics, setInitialMetrics] = useState({
    views: currentViews,
    reactions: currentReactions,
    comments: currentComments,
  });

  useEffect(() => {
    setInitialMetrics({
      views: currentViews,
      reactions: currentReactions,
      comments: currentComments,
    });
    setCurrentMetrics({
      views: currentViews,
      reactions: currentReactions,
      comments: currentComments,
    });
  }, [currentViews, currentReactions, currentComments]);

  return (
    <React.Fragment>
      <tr
        className={`hover:bg-light-blue hover:cursor-pointer border-b bg-gray-200`}
      >
        <td
          className={`py-3 px-4 pl-10 w-60`}
        >
          <div className="h-20 flex-col justify-center items-start gap-1 inline-flex">
            <div className="self-stretch text-black text-lg font-semibold leading-normal tracking-tight">
              {`${index + 1}. ${currentPost.title}`}
            </div>
            <div className="self-stretch text-light-gray text-base font-normal leading-snug tracking-tight">
              {formatDateString(currentPost.created_at)}
            </div>
          </div>
        </td>
        <td className="py-3 px-4 w-32">
          <div
            className={
              "h-6 px-3 rounded justify-start items-center gap-1.5 inline-flex " +
              stateMap[currentPost.status].classNameOuter
            }
          >
            <div
              className={
                "text-center text-sm font-normal leading-tight " +
                stateMap[currentPost.status].classNameInner
              }
            >
              {stateMap[currentPost.status].title}
            </div>
          </div>
        </td>
        <td className="py-3 px-4 w-10">
          <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
            {currentMetrics.views}
          </span>
        </td>
        <td className="py-3 px-4 w-10">
          <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
            {currentMetrics.reactions}
          </span>
        </td>
        <td className="py-3 px-4 w-10">
          <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
            {currentMetrics.comments}
          </span>
        </td>
        <td className="py-8 px-5 w-20">
          {targets ? (
            <div
              onClick={() => {
                if (targets.length > 0) {
                  toggleExpandRow(currentPost.sk);
                }
              }}
              className={`cursor-pointer ${targets.length === 0 ? 'cursor-not-allowed opacity-50' : ''
                }`}
            >
              {expandedRow === currentPost.sk ? (
                <FaChevronUp size={20} />
              ) : (
                <FaChevronDown size={20} />
              )}
            </div>
          ) : (
            <div className="invisible">
              <FaChevronDown size={20} />
            </div>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default PostRow;
