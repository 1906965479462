import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { cloneDeep, random } from "lodash";
import { createStore } from "polotno/model/store";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { DEFAULT_SECTIONS } from "polotno/side-panel";
import { Toolbar } from "polotno/toolbar/toolbar";
import { Workspace } from "polotno/canvas/workspace";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { Tooltip } from "polotno/canvas/tooltip";
import { SidePanel } from "polotno/side-panel";

import { ResizeSection } from "../editor/ResizeSection";
import { TemplatesSectionBuilder } from "../editor/TemplatesSection";
import { TEMPLATES } from "../../data/Templates";
import { ActionControlsBuilder } from "../editor/ActionControls";
import { POLOTNO_KEY } from "../../config/constants";
import * as NotificationService from "../../utils/notificationService";
import {
  generateTemplateContent,
  processImage,
  enhanceImage,
  fetchPost,
  updatePost,
} from "../../services";

import Loading from "../common/Loading";

import "@blueprintjs/core/lib/css/blueprint.css";
import { updateElementContent } from "./utils";
import useLocalStorage from "../../hooks/LocalStorage/useLocalStorage";
import LocalStorageKeys from "../../data/enums/localStorageKeys";

const SKIP_DEFAULT_SECTIONS = [
  "templates",
  "size",
  "background",
  "elements",
  "layers",
];

const store = createStore({
  key: POLOTNO_KEY,
  showCredit: true,
});

function PostEditStep() {
  const navigate = useNavigate();
  const { campaignskuuid, projectskuuid, postSkuuid } = useParams();
  const [storedValue, setStoredValue] = useLocalStorage(
    LocalStorageKeys.EDITED_POST_SKUUID,
    postSkuuid
  );

  const queryClient = useQueryClient();
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);
  const [isGeneratingContent, setIsGeneratingContent] = useState(false);

  const postQuery = useQuery({
    queryKey: [`post-${postSkuuid}`],
    queryFn: async () => fetchPost(campaignskuuid, postSkuuid),
    staleTime: 10 * 1000,
  });
  let post = postQuery.data;

  TEMPLATES[0].preview = post?.image;
  const [currentTemplate, setCurrentTemplate] = useState(
    cloneDeep(TEMPLATES[0])
  );

  const templateChangeCallback = async (template) => {
    const currentTemplateId = template?.pages[0]?.id;
    setIsTemplateLoading(true);
    const _template = cloneDeep(template);
    setCurrentTemplate(_template);
    let customCaptions =
      post?.template_captions && post.template_captions[`${_template?.id}`];

    if (
      !customCaptions &&
      _template?.pages[0]?.children.some(
        (element) => element.type === "text"
      ) &&
      template.id
    ) {
      const jsonFormat = {
        [template.id]: template?.pages[0]?.children.reduce((acc, element) => {
          if (element.type === "text") {
            acc[element.id] = element.text;
          }
          return acc;
        }, {}),
      };

      try {
        const newCaption = await generateTemplateContent(
          jsonFormat,
          campaignskuuid,
          postSkuuid
        );

        queryClient.setQueryData([`post-${postSkuuid}`], (old) => {
          const updatedTemplateCaptions = {
            ...old.template_captions,
            [template.id]: newCaption[template.id],
          };
          return { ...old, template_captions: updatedTemplateCaptions };
        });

        post = queryClient.getQueryData([`post-${postSkuuid}`]);
        customCaptions = post.template_captions[`${_template?.id}`];
      } catch (error) {
        console.error("Error generating template content:", error);
        setIsTemplateLoading(false);
        return null;
      }
    }

    _template?.pages[0]?.children.forEach((element) => {
      updateElementContent(element, post, customCaptions, currentTemplateId);
    });

    await finalizeTemplate(_template);
    return _template;
  };

  const enhanceImageMutation = useMutation({
    mutationFn: async () => {
      setIsLoading(true);
      setIsImageReady(false);
      const imageObj = await enhanceImage(post.id);
      queryClient.setQueryData([`campaign-${post.id}`], (old) => {
        return { ...old, image: imageObj.image };
      });
      setIsImageReady(true);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const finalizeTemplate = async (_template) => {
    setIsTemplateLoading(false);
    setCurrentTemplate(_template);
  };

  useEffect(() => {
    if (isImageReady) {
      templateChangeCallback(currentTemplate).then((t) => {
        store.loadJSON(t);
      });

      setIsImageReady(false);
    }
    if (post?.edit_state && !isGeneratingContent) {
      templateChangeCallback(post.edit_state).then((t) => {
        store.loadJSON(t);
      });
    } else {
      templateChangeCallback(currentTemplate)
        .then((t) => {
          store.loadJSON(t);
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(
            `Failed to generate captions: ${errorMessage}`
          );
        });
      setIsGeneratingContent(false);
    }
  }, [post, isImageReady]);

  const updatedSections = DEFAULT_SECTIONS.filter((e) => {
    return !SKIP_DEFAULT_SECTIONS.includes(e.name);
  });
  updatedSections.push(ResizeSection);
  updatedSections.push(
    TemplatesSectionBuilder(templateChangeCallback, setIsGeneratingContent)
  );
  const cancelAction = () => {
    setStoredValue(postSkuuid);
    navigate(
      `/mkt/projects/${projectskuuid}/campaigns/${campaignskuuid}/assistant`
    );
  };
  const processImageMutation = useMutation({
    mutationFn: async ({ prompt, seed, steps = 100 }) => {
      try {
        setIsTemplateLoading(true);

        if (!post.edit_state) {
          const options = {
            edit_state: store.toJSON(),
          };
          await updatePost(post.id, options);
        }

        setIsLoading(true);
        setIsImageReady(false);

        const imageObj = await processImage(campaignskuuid, postSkuuid, prompt);

        queryClient.setQueryData([`post-${postSkuuid}`], (old) => {
          return { ...old, image: imageObj.image };
        });

        setIsImageReady(true);
      } catch (error) {
        console.error("Error processing image:", error);
      } finally {
        setIsLoading(false);
        setIsTemplateLoading(false);
      }
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const saveAction = async () => {
    try {
      setIsTemplateLoading(true);
      const postPayload = {
        edit_state: store.toJSON(),
        edit_image: await store.toDataURL({
          pageId: currentTemplate?.pages[0].id,
          mimeType: "image/jpg",
        }),
      };

      const postUpdated = await updatePost(post.id, postPayload);
      queryClient.setQueryData([`post-${postSkuuid}`], (old) => {
        return { ...old, ...postUpdated };
      });

      NotificationService.notifySuccess("Campaign updated successfully");
      setStoredValue(postSkuuid);
      navigate(
        `/mkt/projects/${projectskuuid}/campaigns/${campaignskuuid}/assistant`
      );
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to update campaign post: ${errorMessage}`
      );
    } finally {
      setIsTemplateLoading(false);
    }
  };

  return (
    <section className="w-[calc(100vw-450px)] h-[calc(100vh-100px)]">
      <PolotnoContainer className="polotno-app-container">
        <SidePanelWrap>
          <SidePanel
            store={store}
            sections={updatedSections}
            defaultSection="custom-templates"
          />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar
            store={store}
            components={{
              ActionControls: ActionControlsBuilder(
                cancelAction,
                saveAction,
                () =>
                  processImageMutation.mutate({
                    prompt: post.image_prompt,
                    seed: random(0, 10000),
                  }),
                () => enhanceImageMutation.mutate(),
                isLoading
              ),
            }}
          />
          {isTemplateLoading && (
            <div
              style={{
                position: "absolute",
                top: "50px",
                zIndex: "20",
                width: "100%",
                height: "calc(100vh - 20px)",
              }}
              className="bg-gray-800 bg-opacity-50 backdrop-blur-sm flex justify-center items-center"
            >
              <Loading />
            </div>
          )}
          <Workspace
            store={store}
            components={{
              Tooltip,
              PageControls: () => null,
            }}
          />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </section>
  );
}

export default PostEditStep;
