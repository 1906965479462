import React, { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Modal } from "flowbite-react";

import * as NotificationService from "../../utils/notificationService";
import { validateInput } from "../../utils/validations";
import { updateProject } from "../../services";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";
import { extractPkSk } from "../../utils/common";

const EditProjectModal = ({ project, showModal, closeModal }) => {
  const queryClient = useQueryClient();
  const [projectTitle, setProjectTitle] = useState(project.title || "");
  const initialErrors = { title: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  useEffect(() => {
    const isSameData = project.title === projectTitle;
    const isDataEmpty = !projectTitle.trim()
    setIsSaveButtonDisabled(isDataEmpty || isSameData);
  }, [projectTitle]);

  const handleSave = async () => {
    try {
      const { skuuid: projectSkuuid } = extractPkSk(project.id);
      const payload = { title: projectTitle };
      const updatedProject = await updateProject(projectSkuuid, payload);
      NotificationService.notifySuccess("Project updated successfully");
      queryClient.setQueryData(["myProjects"], (oldData) => {
        if (!oldData) return [];
        return oldData.map((p) =>
          p.id === updatedProject.id
            ? { ...p, ...updatedProject }
            : p
        );

      });
    } catch (error) {
      console.error("Failed to create Project: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to update Project: ${errorMessage}`
      );
    } finally {
      closeModal();
    }
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleCancel = () => {
    closeModal();
    setProjectTitle("");
    setErrors(initialErrors);
  };

  const validateProjectTitle = () => {
    validateInput(
      projectTitle,
      "title",
      setErrors,
      "Project title is required"
    );
  };

  const validateAllInputs = () => {
    validateProjectTitle();
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      onClose={handleCancel}
    >
      <Modal.Header className="text-center">Update Project</Modal.Header>
      <Modal.Body className="p-3 py-6">
        <div className="space-y-6">
          <div className="relative">
            <label className="block text-black">Project title *</label>
            <textarea
              className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
              placeholder="Project title*"
              value={projectTitle}
              onBlur={validateProjectTitle}
              onChange={handleInputChange(
                setProjectTitle,
                "title",
                "Project title is required"
              )}
            />
            <InputErrorDisplayer message={errors.title} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel="Update"
            saveDisabled={isSaveButtonDisabled}
            onCancel={handleCancel}
            onSave={handleSave}
            onSaveDisabled={validateAllInputs}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProjectModal;
