import React, { useReducer, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import Papa from "papaparse";

import { ReactComponent as UploadIcon } from "../../assets/upload_files_icon.svg";
import { ReactComponent as ArrowUp } from "../../assets/arrow_up.svg";
import { ReactComponent as TriangleExclamation } from "../../assets/triangle_exclamation.svg";
import { ReactComponent as CircleCheck } from "../../assets/circle_check.svg";
import { ReactComponent as CircleExclamation } from "../../assets/circle_exclamation.svg";
import { initialExpandedSections } from "../../config/constants";
import useCampaignContext from "../../hooks/Campaign/useCampaignContext";
import { isValidFormat, isValidFutureDate } from "../../utils/validations";
import { formatSocialAccountStringToArray } from "../../utils/common";

const reducer = (state, action) => {
  const actions = {
    warning: !state.warning,
    error: !state.error,
    success: !state.success,
    resetState: initialExpandedSections,
  };

  const isExpandible = actions[action.type];

  if (action.type === "resetState") {
    return actions[action.type];
  }

  return { ...state, [action.type]: isExpandible };
};

function FileUploadForm({ knowledgeBasesData = [] }) {
  const { csvData, csvErrors, setCsvData, setCsvErrors } = useCampaignContext();
  const [fileName, setFileName] = useState(null);
  const [expandedSections, dispatch] = useReducer(
    reducer,
    initialExpandedSections
  );
  const dataLength = csvData.length;
  const fileErrorsLength = csvErrors.length;
  const isFileError = fileErrorsLength > 0;
  const isFileSuccessful = fileErrorsLength === 0 && dataLength > 0;

  const handleFileChange = (e) => {
    dispatch({ type: "resetState" });

    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const data = results.data;
          if (data.length > 0) {
            setCsvErrors([]);
            setCsvData(data);
            validateCsvData(data);
          }
        },
      });
    }
  };

  const getMissingFields = (post) => {
    const requiredFields = ["title", "prompt", "ai_model"];
    return requiredFields.filter((field) => !post[field]);
  };

  const validateKnowledgeBase = (id) => {
    if (!id) return "";

    const knowledge_base = knowledgeBasesData.find((kb) => kb.id === id);
    return knowledge_base ? "" : `Invalid knowledge base id: ${id}. `;
  };

  const validateSocialAccountSchedules = (socialAccountSchedules) => {
    if (!socialAccountSchedules) return "";

    let dateErrorMessage = "";

    if (!isValidFormat(socialAccountSchedules)) {
      return `Expected format for social_account_schedules: [(social_id MM/DD/YYYY HH:MM)].`;
    }

    const nestedArray = formatSocialAccountStringToArray(
      socialAccountSchedules
    );
    nestedArray.forEach((socialAccountSchedule, idx) => {
      const scheduleDate = socialAccountSchedule[1];
      const date = isValidFutureDate(scheduleDate);
      if (!date) {
        dateErrorMessage += `Invalid date in position: ${
          idx + 1
        } - Only future dates are allowed: ${scheduleDate}`;
      }
    });

    return dateErrorMessage;
  };

  const validateFields = (post, idx) => {
    let errorMessage = "";
    const missingFields = getMissingFields(post);
    const knowledgeBaseError = validateKnowledgeBase(post.knowledge_base_id);
    const socialAccountScheduleErrors = validateSocialAccountSchedules(
      post.social_account_schedules
    );

    if (missingFields.length > 0) {
      errorMessage += `Missing fields: ${missingFields.join(", ")}. `;
    }

    if (knowledgeBaseError) {
      errorMessage += knowledgeBaseError;
    }

    if (socialAccountScheduleErrors) {
      errorMessage += socialAccountScheduleErrors;
    }

    return errorMessage
      ? { errorTitle: `Post: ${idx + 1}`, message: errorMessage }
      : null;
  };

  const handleExpansion = (action) => {
    dispatch({ type: action });
  };

  const validateCsvData = (data) => {
    const errors = [];

    if (
      data[0]["title"] === undefined ||
      data[0]["prompt"] === undefined ||
      data[0]["ai_model"] === undefined
    ) {
      errors.push({
        errorTitle: "Posts",
        message: "The CSV does not match the template format",
      });
      setCsvErrors(errors);
      return;
    }

    for (const [idx, post] of data.entries()) {
      const result = validateFields(post, idx);
      if (result) errors.push(result);
    }

    setCsvErrors(errors);
  };

  const StatusMessage = ({ icon, message, status }) => {
    return (
      <div className="flex gap-1 py-4">
        <div className="flex items-center grow space-x-2">
          {icon}
          <span className="text-[#121212B2] text-sm font-normal">
            {message}
          </span>
        </div>
        <button onClick={() => handleExpansion(status)}>
          {expandedSections[status] ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
    );
  };

  return (
    <div
      className={`
        flex flex-col px-4 
        ${(isFileSuccessful || isFileError) && "pb-4"} 
        border border-gray-300 rounded w-[456px] gap-y-3
        max-h-40 overflow-y-auto`}
    >
      <div
        className={`flex py-4 ${
          (isFileSuccessful || isFileError) && "border-b"
        }`}
      >
        <div className="flex items-center grow space-x-2">
          <UploadIcon className="w-8 h-8" />
          <span className="text-sm font-bold">
            {fileName ?? "Upload CSV File"}
          </span>
        </div>
        <label
          htmlFor="fileInput"
          className="bg-[#1DA1F2] text-white text-xs w-[117px] h-[40px] flex items-center justify-center gap-x-2.5 cursor-pointer"
        >
          <ArrowUp className="w-3 h-3 text-white" />
          <>Upload File</>
          <input
            className="hidden"
            id="fileInput"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
        </label>
      </div>

      {isFileError && (
        <StatusMessage
          icon={<TriangleExclamation className="w-4 h-4" />}
          message="Upload Completed with Errors"
          status="warning"
        />
      )}

      {isFileSuccessful && (
        <StatusMessage
          icon={<CircleCheck className="w-4 h-4" />}
          message="Upload Completed successfully"
          status="success"
        />
      )}

      {expandedSections.success && (
        <div className="flex gap-1 py-3 border-b  border-gray-300">
          <span className="text-[#00B93E] grow text-xs font-normal">
            {`Succesfully uploaded: ${dataLength} posts`}
          </span>
        </div>
      )}

      {expandedSections.warning && (
        <>
          <div className="flex gap-1 py-3">
            <span className="text-[#FF4144] grow text-xs font-normal">
              {`Errors encoutered: ${fileErrorsLength} posts`}
            </span>
            <button onClick={() => handleExpansion("error")}>
              {expandedSections.error ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>
        </>
      )}

      {expandedSections.error && (
        <div className="flex flex-col gap-y-1 pb-2">
          {csvErrors.map((file, idx) => (
            <React.Fragment key={`${idx}-${file.errorTitle}`}>
              <div className="flex gap-1 pt-2">
                <CircleExclamation className="w-[14px] h-[14px]" />
                <span className="text-[#FF4144] text-xs">
                  {file.errorTitle}
                </span>
              </div>
              <div className="flex gap-1 border-b border-gray-300 pb-2">
                <span className="text-[#FF4144] text-xs pr-1">Error:</span>
                <span className="text-xs">{file.message}</span>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
}

export default FileUploadForm;
