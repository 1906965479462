import React, { useState } from "react";
import { Modal } from "flowbite-react";
import { validateInput } from "../../utils/validations";
import * as NotificationService from "../../utils/notificationService";
import Dropdown from "../common/forms/Dropdown";
import {
  socialPlatformConnectCallbacksMap,
  getSocialPlatformName,
} from "../../utils/socialPlatformUtils";
import SocialNetworkType from "../../data/enums/socialNetworkType";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";

const AddSocialAccountModal = ({ openModal, setOpenModal }) => {
  const initialErrors = {
    account_name: "",
    social_network: "",
  };
  const [errors, setErrors] = useState(initialErrors);
  const [inputValid, setInputValid] = useState({
    account_name: true,
    social_network: true,
  });
  const initialAccount = {
    account_name: "",
    social_network: SocialNetworkType.X,
  };
  const [newAccount, setNewAccount] = useState(initialAccount);

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter((prevState) => ({ ...prevState, [field]: value }));
    validateInput(value, field, setErrors, errorMessage);
  };

  const connectSocialAccountHandler = async () => {
    try {
      const isAccountNameValid = newAccount.account_name.trim() !== "";
      const isSocialNetworkValid = newAccount.social_network !== "";

      setInputValid({
        account_name: isAccountNameValid,
        social_network: isSocialNetworkValid,
      });

      if (!isAccountNameValid || !isSocialNetworkValid) {
        return;
      }

      socialPlatformConnectCallbacksMap[
        getSocialPlatformName(newAccount.social_network)
      ]?.(newAccount.account_name);

      setOpenModal(false);
    } catch (error) {
      console.error("Failed to connect social account: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to connect social account: ${errorMessage}`
      );
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setNewAccount(initialAccount);
    setErrors(initialErrors);
  };

  const validateAccountName = () => {
    validateInput(
      newAccount.account_name,
      "account_name",
      setErrors,
      "Account name is required."
    );
  }

  const validateAllInputs = () => {
    validateAccountName();
  };

  return (
    <Modal show={openModal} onClose={handleCancel}>
      <Modal.Header className="text-center">Add Account</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <div className="relative">
            <input
              type="text"
              id="account_name"
              value={newAccount.account_name}
              onChange={handleInputChange(
                setNewAccount,
                "account_name",
                "Account name is required."
              )}
              onBlur={validateAccountName}
              className={`relative ${
                !inputValid.account_name
                  ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                  : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
              }`}
              placeholder=" "
            />
            <label
              htmlFor="account_name"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Account name *
            </label>
          </div>
          <InputErrorDisplayer message={errors.account_name}/>
          <Dropdown
            selectedOption={newAccount.social_network}
            options={[SocialNetworkType.X, SocialNetworkType.LINKEDIN]}
            onSelect={(option) =>
              setNewAccount((prevState) => ({
                ...prevState,
                social_network: option,
              }))
            }
            labelExtractor={(option) => SocialNetworkType.toReadable(option)}
            defaultTitle="Social Network"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel="Create"
            saveDisabled={newAccount.account_name.trim().length === 0}
            onSave={connectSocialAccountHandler}
            onCancel={handleCancel}
            onSaveDisabled={validateAllInputs}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSocialAccountModal;
