import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";


export const createTargetPlatform = async (payload) => {
  try{
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/target-platforms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload)
    });
  
    const data = await response.json();
    return data;

  }catch(error){
    console.error("Failed to create target platform:", error);
    throw new Error("Error occurred creating target platform.");
  }
}

export const fetchPostTargetPlatforms = async (postSkuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/posts/${postSkuuid}/target-platforms`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to fetchPostTargetPlatforms:", error);
    throw error;
  }
};

export const updateTargetPlatformMetrics = async (metrics) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/target-platforms/metrics`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(metrics),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to updateCampaignMetrics:", error);
    throw error;
  }
}