import React, { useState, useRef, useEffect } from "react";
import { truncateContent } from "../../../utils/common";

const EditableSpan = ({ 
  initialContent, 
  charactesLimit,
  onSave, 
  validateInput = (value) => !!value.trim(), 
  setIsInput = ()=>{},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(initialContent);
  const [auxContent, setAuxContent] = useState(initialContent);
  const ref = useRef(null);

  const handleDoubleClick = () => {
    setIsEditing(true);
    setIsInput(true);
  };

  const handleChange = (e) => {
    setContent(e.target.value);
  };

  const saveChanges = async () => {
    try {
      if (!validateInput(content)) {
        throw new Error("Invalid input");
      }
      if (content === auxContent) return auxContent;

      const updatedValue = await onSave(content);
      return updatedValue;
    } catch (error) {
      console.error("Failed to save changes: ", error);
      setContent(initialContent);
    } finally {
      setIsInput(false);
      setIsEditing(false);
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const valueUpdated = await saveChanges();
      setAuxContent(valueUpdated);
    }
    if (e.key === "Escape") {
      e.preventDefault();
      setContent(auxContent);
      setIsInput(false);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = async (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const valueUpdated = await saveChanges();
        setAuxContent(valueUpdated);
        setIsInput(false);
        setIsEditing(false);
      }
    };

    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };


  }, [isEditing, saveChanges]);

  const { truncatedText, isTruncated } = truncateContent(content, charactesLimit);

  return (
    <div className="w-full">
      {!isEditing ? (
        <span 
          onDoubleClick={handleDoubleClick} 
          className="flex text-sm cursor-text"
          title={isTruncated ? content : null}
        >
          {truncatedText}
        </span>
      ) : (
        <input 
          className="border-0 focus:outline-none bg-transparent focus:ring-0 p-0 m-0 flex text-sm cursor-text h-5 w-full"
          type="text"
          autoFocus 
          ref={ref} 
          value={content} 
          onKeyDown={handleKeyDown} 
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default EditableSpan;