import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";

import { formatDateWithOrdinal, truncateContent, copyToClipboardHandler } from "../../utils/common";
import DataSourceType from "../../data/enums/data_source_type_enum";
import KbStatusType from "../../data/enums/Kb_statusType";
import { ReactComponent as ClipboardIcon } from "../../assets/clipboard.svg";
import delete_data from "../../assets/delete_data_filter.svg";
import StatusBadge from "../common/StautsBagde";

function KnowledgeBaseCard({
  knowledgeBaseData,
  deleteKnowledgeBaseHandler,
}) {
  const navigate = useNavigate();
  const [kbIdCopied, setKbIdCopied] = useState(null);

  const filters = knowledgeBaseData?.knowledge_base_data_filters || [];
  const kbNameTruncated = truncateContent(knowledgeBaseData.title, 30).truncatedText;

  function formatFilterNameAndSource(filter, filterNameMaxLength) {
    let filterName = filter.name || "No filter name";
    if (filterNameMaxLength && filterNameMaxLength > 0) {
      filterName = truncateContent(filterName, filterNameMaxLength).truncatedText;
    }
    const filterSource = DataSourceType.getReadableFromString(filter.source) || "No filter source";
    return `${filterName} - ${filterSource}`;
  }

  const maxDFDisplayed = 3;
  const displayedFilters = filters.slice(0, maxDFDisplayed);
  const hiddenFilters = filters.length > maxDFDisplayed ? filters.slice(maxDFDisplayed) : [];
  const hiddenFiltersTitle = hiddenFilters.map((filter) => formatFilterNameAndSource(filter)).join(", ");

  const copyKBIdHandler = (e) => {
    e.stopPropagation();
    copyToClipboardHandler(knowledgeBaseData.id, setKbIdCopied);
  };

  const borderMap = {
    [KbStatusType.EMPTY]: "border border-2 border-gray-200",
    [KbStatusType.PROCESSING]: "border border-2 border-gray-200",
    [KbStatusType.READY]: "border border-2 border-main-blue-light",
    [KbStatusType.FAILED]: "border border-2 border-gray-200",
};

  const isProcessing = knowledgeBaseData.status === KbStatusType.PROCESSING;
  const cardBorder = borderMap[knowledgeBaseData.status] || "";

  return (
    <div
      key={knowledgeBaseData.id}
      className={`relative flex flex-col justify-between items-center rounded-md shadow-lg w-full max-w-[284px] min-h-[240px] p-4 cursor-pointer ${cardBorder}`}
      onClick={() => {
        navigate(`/mkt/knowledgebase/edit`, {
          state: { kb: knowledgeBaseData },
        });
      }}
    >
      {isProcessing && (
        <div className="absolute top-0 left-0 w-full h-1 bg-gray-200 rounded-t-md">
          <div className="h-full bg-gray-500 rounded-t-md w-1/2"></div>
        </div>
      )}

      <div
        title={knowledgeBaseData.title}
        className="w-full text-lg md:text-xl text-center font-bold text-[#6B6B6B] px-2 break-words overflow-hidden text-ellipsis mt-2 mb-1 line-clamp-2 min-h-[56px] flex justify-center items-start"
      >
        <span>
          {kbNameTruncated}
          {knowledgeBaseData.status && (
            <StatusBadge status={knowledgeBaseData.status} />
          )}
        </span>
      </div>

      <div className="w-full text-xs md:text-xs text-[#6B6B6B] font-bold text-center py-1">
        Created on {" "} {formatDateWithOrdinal(knowledgeBaseData.created_at) ?? "-"}
      </div>

      <div className="w-full">
        {filters.length > 0 ? (
          <>
            <span className="text-xs text-[#6B6B6B] font-bold">Data filters:</span>
            <ul
              className="list-disc list-inside text-xs md:text-sm text-[#6B6B6B] list-none"
            >
              {displayedFilters.map((filter) => (
                <li key={filter.filter_id} className="" title={formatFilterNameAndSource(filter)}>
                  {formatFilterNameAndSource(filter, 10)}
                </li>
              ))}
              {hiddenFilters.length > 0 && (
                <li 
                  title={hiddenFilters.length > 0 ? `More filters: ${hiddenFiltersTitle}` : ""}
                  className="text-xs font-bold text-center"
                >
                  See more
                </li>
              )}
            </ul>
          </>
        ) : (
          <div className="text-xs md:text-sm text-[#6B6B6B]">No filters available</div>
        )}
      </div>

      <div className="flex-grow"></div>

      <div className="w-full absolute bottom-3 flex justify-center items-center gap-2">
        <FaPencilAlt className="text-gray-600 h-4 w-4" />
        <img
          src={delete_data}
          alt="Delete"
          className="cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            deleteKnowledgeBaseHandler(knowledgeBaseData.id);
          }}
        />
        <button
          onClick={copyKBIdHandler}
          title="Copy knowledge base ID"
        >
          <ClipboardIcon className="h-5 w-5" />
        </button>
      </div>

      {kbIdCopied && (
        <div className="absolute bottom-3 right-3 text-xs text-green-600">
          Copied!
        </div>
      )}
    </div>
  );
};

export default KnowledgeBaseCard;
