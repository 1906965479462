import StatusType from "../data/enums/statusType";
import { FaCheck, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../assets/edit_icon.svg";
import Button from "./common/Button";

export default function PostPreviewHeader({ 
		postInfo, 
		isApprovedLoading, 
		handleApprove, 
		deletePostHandler, 
		projectskuuid, 
		campaignskuuid, 
		postSkuuid, 
		canEdit, 
		onClickPublishButton 
	}) {
  const navigate = useNavigate();
  
	return (
		<div className="px-4 pt-2 sm:px-1 flex flex-row justify-between gap-1 items-center mb-2">
		{postInfo.caption && postInfo.image && canEdit ? (
			<div className="lg:basis-[43%] xl:basis-[30%] lg:ml-3 flex items-center justify-center">
				<button
					onClick={() => {
						navigate(
							`/mkt/projects/${projectskuuid}/campaigns/${campaignskuuid}/posts/${postSkuuid}/edit`
						);
						window.location.reload();
					}}
					className="flex items-center font-medium text-main-blue hover:text-dark-blue focus:outline-none"
					aria-label="Edit design studio"
				>
					<EditIcon className="text-light-gray hover:text-dark-gray transition-colors duration-200" />
					<span className="ml-2">Design Studio</span>
				</button>
			</div>
				) : (
					<div className="w-11 h-11 rounded-3xl bg-mid-light-gray lg:basis-[43%] xl:basis-[10%]"></div>
		)}
    {postInfo.status === StatusType.IN_REVIEW ? (
			<div className="flex justify-end gap-x-2">
			<button
				className="h-10 px-6 py-2.5 gap-1 justify-center items-center inline-flex text-white hover:cursor-pointer bg-[#EF4444] hover:bg-red-600 rounded-3xl lg:basis-[50%] xl:basis-[30%]"
				onClick={() => deletePostHandler(postInfo)}
				>
				<FaTrashAlt />
				Delete
			</button>
			{!isApprovedLoading ? (
				<button
					className="h-10 px-4 py-2.5 gap-1 justify-center items-center inline-flex text-white hover:cursor-pointer bg-[#22C55E] hover:bg-green-500 rounded-3xl lg:basis-[50%] xl:basis-[30%]"
					onClick={handleApprove}
				>
					<FaCheck />
					Approve
				</button>
			) : ( 
				<div className="h-10 px-4 py-2.5 gap-1 bg-green-400 text-white flex items-center justify-center gap-1 rounded-3xl lg:basis-[50%] xl:basis-[30%]">
					<span className="loader border-t-2 border-white rounded-full w-3 h-3 animate-spin"></span>
					<span>Approving...</span>
				</div>
			)}
			</div>
		) : (
			<div className="relative my-2">
			{postInfo.caption && postInfo.image ? (
				<div
				className="relative"
				style={{ color: "#1DA1F2", borderColor: "#1DA1F2" }}
				>
					<Button
						onClick={onClickPublishButton}
						className="w-40"
						disabled={!canEdit}
						>
						Publish
					</Button>
				</div>
			) : (
				<div className="w-24 h-11 rounded-3xl bg-mid-light-gray"></div>
			)}
			</div>
		)}
		</div>
  );}
