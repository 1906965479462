import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";
import { extractPkSk } from "../utils/common";


export const createCampaign = async (payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createCampaign:", error);
    throw error;
  }
};

export const fetchCampaign = async (projectskuuid, campaignSkuuid) => {
  const session = await fetchAuthSession();
  const response = await fetch(`${API_SERVER}/projects/${projectskuuid}/campaigns/${campaignSkuuid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.tokens.idToken.toString()}`,
    },
  });
  const responseData = await response.json();
  if (!response.ok)
    throw new Error(responseData.error || "Network response was not ok");
  return responseData;
};

export const updateCampaign = async (projectSkuuid, campaignSkuuid, data) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/projects/${projectSkuuid}/campaigns/${campaignSkuuid}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to updateCampaign:", error);
    throw error;
  }
};

export const deleteCampaign = async (campaignid) => {
  try {
    const { pkuuid, skuuid } = extractPkSk(campaignid);
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/projects/${pkuuid}/campaigns/${skuuid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to deleteCampaign:", error);
    throw error;
  }
};

export const fetchCampaignPosts = async (campaignSkuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaign/${campaignSkuuid}/posts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createPost:", error);
    throw error;
  }
};

export const fetchProjectCampaigns = async (projectskuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/projects/${projectskuuid}/campaigns`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to fetchProjectCampaigns:", error);
    throw error;
  }
};
