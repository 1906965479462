class KbStatusType {
    static PROCESSING = "PROCESSING"
    static EMPTY = "EMPTY"
    static FAILED = "FAILED"
    static READY = "READY"
    static IN_PROGRESS = "IN_PROGRESS"

    static toReadable(type) {
        switch (type) {
            case this.PROCESSING:
                return 'Processing';
            case this.EMPTY:
                return "Empty";
            case this.FAILED:
                return "Failed";
            case this.READY:
                return "Ready";
            case this.IN_PROGRESS:
                return "In progress";
            default:
                return null;
        }
    }
}

export default KbStatusType;
