import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import Image from "./image_generation/Image";
import { ReactComponent as ScheduledStatusIcon } from "../assets/scheduled_status_icon.svg";
import PublishModal from "./Campaigns/CampaignPage/PublishModal";
import SourcesContainer from "./Campaigns/SourcesContainer";
import CaptionContainer from "./Campaigns/CaptionContainer";
import StatusType from "../data/enums/statusType";
import { ReactComponent as PublishedIcon } from "../assets/icons/published_icon.svg";
import SchedulePostModal from "./SchedulePostModal";
import * as NotificationService from "../utils/notificationService";
import { isEmpty } from "../utils/validations";
import {
  getDateAndTimeList,
  convertDateToISO,
  extractPkSk,
} from "../utils/common";
import {
  schedulePost,
  publishPost,
  updatePost,
} from "../services";
import PostPreviewHeader from "./PostPreviewHeader";


function PostPreviewSide({
  postQuery,
  className,
  references,
  deletePostHandler,
  projectSocialAccounts,
  postTargetPlatforms,
}) {
  const postData = postQuery.data;
  const { campaignskuuid, projectskuuid } = useParams();
  const queryClient = useQueryClient();
  const [postInfo, setPost] = useState(postData);
  const [showMoreText, setShowMoreText] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [postCaption, setPostCaption] = useState(postInfo.caption);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [currentTargetPlatform, setCurrentTargetPlatform] = useState(null);
  const [scheduleTargetPlatformData, setScheduleTargetPlatformData] = useState({
    start_date: "",
    start_time: "",
  });
  const [isApprovedLoading, setIsApprovedLoading] = useState(false);
  const [selectedTargetPlatforms, setSelectedTargetPlatforms] = useState([]);

  const formatScheduleDate = (target_platform) => {
    if(!target_platform){
      return null;
    }

    if (!target_platform.includes("T")){
      throw new Error("Expected ISO 8601 format.")
    }

    const date = new Date(target_platform);
    return date.toISOString();
  }

  const handleApprove = async () => {
    try {
      setIsApprovedLoading(true);

      const payload = postTargetPlatforms.map((target_platform) => {

        const scheduleDate = formatScheduleDate(target_platform.schedule_time);

        return {
          post_id: target_platform.post_id,
          social_account_id: target_platform.social_account_id,
          schedule_time: scheduleDate
            ? scheduleDate
            : target_platform.schedule_time,
          target_platform_id: target_platform.sk,
        };
      });

      await Promise.all(payload.map(schedulePost));
      await updatePost(postInfo.id, { status: StatusType.READY });
      NotificationService.notifySuccess(
        "The campaign was approved successfully."
      );
      postQuery.refetch();
    } catch (error) {
      NotificationService.notifyError(
        `Failed to Approved the campaign: ${error.message}`
      );
    } finally {
      setIsApprovedLoading(false);
    }
  };

  const handleSaveEditedCaption = async () => {
    await updatePost(postInfo.id, { caption: postCaption });
  };

  useEffect(() => {
    const postScheduledData = getDateAndTimeList(
      currentTargetPlatform && currentTargetPlatform.schedule_time
    );

    setPostCaption(postData.caption);
    setPost(postData);
    setScheduleTargetPlatformData({
      start_date: postScheduledData[0],
      start_time: postScheduledData[1],
    });
  }, [postData, currentTargetPlatform]);

  const { skuuid: postSkuuid } = extractPkSk(postInfo.id);
  
  const handleSchedulePost = async () => {
    setIsPublishing(true);
    const publish = async () => {
      try {
        const publishForAccount = async (currentSelectedTargetPlatform) => {
          const payload = {
            post_id: postInfo.id,
            social_account_id: currentSelectedTargetPlatform.socialAccountId,
            schedule_time: convertDateToISO(
              scheduleTargetPlatformData.start_date,
              scheduleTargetPlatformData.start_time
            ),
            target_platform_id: currentSelectedTargetPlatform.targetPlatformId,
          };
          return schedulePost(payload);
        };
        const publishPromises = selectedTargetPlatforms.map(publishForAccount);
        const results = await Promise.allSettled(publishPromises);

        const successfulResults = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => result.value);
        const errors = results
          .filter((result) => result.status === "rejected")
          .map((result) => result.reason);

        const lastIndex = successfulResults.length - 1
        const targetPlatforms = successfulResults[lastIndex];

        queryClient.setQueryData([`post-${postSkuuid}-platform-targets`], targetPlatforms);

        if (errors.length > 0) {
          NotificationService.notifyError(`Some posts failed to schedule`);
        } else {
          NotificationService.notifySuccess("Post scheduled successfully!");
        }

        setOpenScheduleModal(false);
        setOpenPublishModal(true);
        setSelectedTargetPlatforms([]);
      } catch (error) {
        console.error("Failed to schedule post: ", error);
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(
          `Failed to schedule post: ${errorMessage}`
        );
      } finally {
        setIsPublishing(false);
      }
    };

    await publish();
  };

  const handlePublishPost = async () => {
    setIsPublishing(true);
    const publish = async () => {
      try {
        const { skuuid: postSkuuid } = extractPkSk(postInfo.id);

        const publishForAccount = async (currentSelectedTargetPlatform) => {
          const payload = {
            post_id: postInfo.id,
            include_references: showReferences,
            social_account_id: currentSelectedTargetPlatform.socialAccountId,
          };
          return publishPost(payload);
        };

        const publishPromises = selectedTargetPlatforms.map(publishForAccount);
        const results = await Promise.allSettled(publishPromises);

        const successfulResults = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => result.value);
        const errors = results
          .filter((result) => result.status === "rejected")
          .map((result) => result.reason);

        const targetPlatforms = successfulResults.flat();

        queryClient.setQueryData(
          [`post-${postSkuuid}-platform-targets`],
          (oldData) => {
            if (!oldData) return targetPlatforms;
            const updatedList = oldData.map((item) => {
              const matchingItem = targetPlatforms.find(
                (platform) => platform.id === item.id
              );
              return matchingItem ? { ...item, ...matchingItem } : item;
            });
            const newItems = targetPlatforms.filter(
              (platform) => !oldData.some((item) => item.id === platform.id)
            );
            return [...updatedList, ...newItems];
          }
        );

        if (errors.length > 0) {
          NotificationService.notifyError(`Some posts failed to publish`);
        } else {
          NotificationService.notifySuccess("Post published successfully!");
        }
      } catch (error) {
        console.error("Failed to publish post: ", error);
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(
          `Failed to publish post: ${errorMessage}`
        );
      } finally {
        setIsPublishing(false);
      }
    };

    await publish();
  };

  const renderState = () => {
    switch (postInfo.state) {
      case StatusType.PUBLISHED:
        return (
          <>
            <PublishedIcon /> {StatusType.toReadable(StatusType.PUBLISHED)}
          </>
        );
      default:
        return (
          <>
            <ScheduledStatusIcon /> {StatusType.toReadable(StatusType.READY)}
          </>
        );
    }
  };

  const canEdit = useMemo(() => {
    if (isPublishing) return !isPublishing;
    return postInfo?.status !== StatusType.PUBLISHED;
  }, [postInfo.status, isPublishing]);

  const handleDateTimeChange = (e) => {
    const { id, value } = e.target;
    if (id === "campaign_start_date") {
      setScheduleTargetPlatformData((prevState) => ({
        ...prevState,
        start_date: value,
      }));
    } else if (id === "campaign_start_time") {
      setScheduleTargetPlatformData((prevState) => ({
        ...prevState,
        start_time: value,
      }));
    }
  };

  const onClickPublishButton = () => {
    setOpenPublishModal(true);
  };

  const handlePublishSchedule = () => {
    setCurrentTargetPlatform(null);
    setOpenPublishModal(false);
    setOpenScheduleModal(true);
  };

  const handleScheduleCancel = () => {
    setOpenPublishModal(true);
    setOpenScheduleModal(false);
  };

  return (
    <div className={className + " flex flex-col justify-start"}>
      <PostPreviewHeader
        postInfo={postInfo}
        campaignskuuid={campaignskuuid}
        projectskuuid={projectskuuid}
        postSkuuid={postSkuuid}
        canEdit={canEdit}
        onClickPublishButton={onClickPublishButton}
        deletePostHandler={deletePostHandler}
        handleApprove={handleApprove}
        isApprovedLoading={isApprovedLoading}
      />
      {postInfo.image_prompt ? (
        <Image key={postInfo?.id} post={postInfo} disabled={canEdit} />
      ) : (
        <div className="h-[521px] bg-mid-light-gray"></div>
      )}

      <CaptionContainer
        postCaption={postCaption}
        originalCaption={postInfo.caption}
        isEditing={isEditing}
        setPostCaption={setPostCaption}
        setShowMoreText={setShowMoreText}
        showMoreText={showMoreText}
        setIsEditing={setIsEditing}
        renderState={renderState}
        saveEdition={handleSaveEditedCaption}
      />
      {references.length > 0 && !isEmpty(references[0]) && (
        <SourcesContainer
          references={references}
          showReferences={showReferences}
          setShowReferences={setShowReferences}
        />
      )}

      <PublishModal
        openModal={openPublishModal}
        setOpenModal={setOpenPublishModal}
        projectSocialAccounts={projectSocialAccounts}
        postTargetPlatforms={postTargetPlatforms}
        handlePublish={handlePublishPost}
        handleSchedule={handlePublishSchedule}
        selectedTargetPlatforms={selectedTargetPlatforms}
        setSelectedTargetPlatforms={setSelectedTargetPlatforms}
      />

      <SchedulePostModal
        openModal={openScheduleModal}
        setOpenModal={setOpenScheduleModal}
        scheduleTargetPlatformData={scheduleTargetPlatformData}
        handleDateTimeChange={handleDateTimeChange}
        schedulePostHandler={handleSchedulePost}
        handleCancel={handleScheduleCancel}
        currentTargetPlatform={currentTargetPlatform}
        setCurrentTargetPlatform={setCurrentTargetPlatform}
      />
    </div>
  );
}

export default PostPreviewSide;
